.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 98vw;
  overflow: hidden;
}

.game {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.board {
  display: flex;
  flex-direction: column;
}

.board-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  font-size: 0;
}

.tile-container {
  width: 100%;
  height: auto;
  margin: 1px;
}

.tile {
  width: 100%;
  height: auto;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 4vh;
}

.button {
   background-color: #505050;
   border: none;
   color: white;
   font-weight: bold;
   padding: 16px 26px;
   margin: 0px 6px;
   text-align: center;
   text-decoration: none;
   font-size: 18px;
   cursor: pointer;
   border-radius: 6px;
 }

/* Mobile */
@media only screen and (max-width: 440px) {
  .board {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
  }

}

/* Above mobile */
@media only screen and (min-width: 441px) {
  .board {
    display: flex;
    flex-direction: column;
    width: 315px;
    height: auto;
  }
}

